.loginContainer {
  display: flex;
  width: 100%;
  position: relative;
}

.loginLeftContainer {
  width: 50%;
  height: 100vh;
  background-color: #cf4633;
  color: #fff;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: center;
}

.loginRightContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  height: 100vh;

  width: 50%;
}

.loginRightContainer form {
  width: 300px;
}

.userInput {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.userInput label {
  margin: 5px 0;
}

.userInput input {
  padding: 7px;
  border-radius: 15px;
  border: 1px solid #000;
}

.userPassword {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loginRightContainer a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
}

.userPassword label {
  margin: 5px 0;
}

.userPassword input {
  padding: 7px;
  border-radius: 15px;
  border: 1px solid #000;
}

.loginRightContainer button {
  display: block;
  background-color: #cf4633;
  border-radius: 20px;
  color: #fff;

  width: 300px;
  padding: 10px 0;
  margin: 15px 0;
}
