.careersBackground {
  background-image: linear-gradient(
      to right bottom,
      rgba(0, 0, 0, 0.572),
      rgba(8, 8, 8, 0.698)
    ),
    url("../../../public/JoinOurTeam2.jpeg");

  position: relative;
  background-size: cover;
  background-position: center;
  height: 500px;
  display: grid;
  place-content: center;
  color: aliceblue;
}

.careersBackground h1 {
  font-size: 100px;
}

.careersDetail {
  margin: 100px 0;
}

.careersImage img {
  max-width: 100%;
  height: auto;
  border-radius: 25px;
}

.careersText {
  text-align: left;
  margin-top: 20px;
}

.careersText a {
  text-decoration: none;
}

@media screen and (max-width: 620px) {
  .careersBackground h1 {
    font-size: 70px;
  }
}

@media screen and (max-width: 433px) {
  .careersBackground h1 {
    font-size: 50px;
  }
}
