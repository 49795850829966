.terms {
  text-align: left;
}

.terms h1,
.terms h2,
.terms p {
  margin: 50px 10px;
}

.terms h4 {
  margin: 10px;
}

.terms span {
  margin: 0 10px;
}
