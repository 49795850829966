/* breadcrumbs container */
.breadcrumbsContainer {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 0;
  padding: 20px;
}

.breadcrumbsRight {
  display: flex;
  align-items: center;
  gap: 20px;
}

.avatarContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.avatar {
  border-radius: 54px;
  width: 34px;
  height: 34px;
  background-color: #1b1b1b;
}

/* Searchbar */
