.newsletterMain {
  height: 100vh;
  width: 80vw;
  padding: 0 20px;
  overflow-y: scroll;
}

.newsletterContent h3 {
  text-align: left;
  margin: 25px 0;
}

.newsletterStats {
  box-shadow: -8px 8px 13px #ededed, 8px -8px 13px #ffffff;
  border-radius: 25px;
  display: flex;
  justify-content: space-evenly;
  margin: 70px 0;
  padding: 20px 0;
}

.newsletterStat {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: left;
  width: 300px;
}

.newsletterStat:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}

.newsletterStat small {
  display: flex;
}

.newsletterStat small span {
  color: #00ac4f;
  margin: 0 3px;
}

.newsletterStatTwo small span {
  color: #d0004b;
}

.newsletterStatImg {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
}

.newsletterStatOne .newsletterStatImg {
  background: linear-gradient(#d3ffe7, #effff6);
}

.newsletterStatTwo .newsletterStatImg {
  background: linear-gradient(#caf1ff, #cdf4ff);
}

.newsletterStatThree .newsletterStatImg {
  background: linear-gradient(#ffa3cf, #ffd4f3);
}

.newsletterCharts {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.newsletterOverview {
  background-color: #fff;
  box-shadow: -8px 8px 13px #ededed, 8px -8px 13px #ffffff;
  border-radius: 15px;
  padding: 10px;
}

.newsletterReaders {
  background-color: #fff;

  border-radius: 15px;
  box-shadow: -8px 8px 13px #ededed, 8px -8px 13px #ffffff;
  text-align: left;
  padding: 20px;
}

.newsletterTextRow {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.newsletterTextRow select {
  background-color: #f9fbff;
  padding: 10px;
}

.newsletterTable {
  margin: 50px 0 50px 10px;
  border-radius: 15px;
  box-shadow: -8px 8px 13px #ededed, 8px -8px 13px #ffffff;
  background-color: #fff;

  padding: 20px;
}

.newsletterHeader {
  display: flex;
  padding: 10px 0;
  margin: 20px 0;
  text-align: left;
}

.newsletterHeader select {
  background-color: #f9fbff;
  padding: 10px;
  border-radius: 15px;
  outline: none;
}

.newsletterHeader h1 {
  margin-right: auto;
}

.newsletterTable table {
  width: 1000px;
}

.newsletterTableInput {
  margin-right: 20px;
}

.tableHeading {
  text-align: left;
}

.tableData {
  display: flex;
  text-align: left;
}

.tableDataImage {
  background-color: black;
  border-radius: 20px;
  height: 80px;
  width: 100px;
  margin-right: 30px;
}

.newsletterForm {
  text-align: left;
  margin: 80px 0;
}

.newsletterForm h2 {
  margin: 100px 0;
}

.newsletterBtns {
  display: flex;
  justify-content: flex-end;
}

.newsletterCancelBtn {
  background-color: #f9fbff;
  border: 1px solid #000;
  border-radius: 15px;
  padding: 5px 20px;
}

.newsletterSubmitBtn {
  background-color: #cf4633;
  border-radius: 15px;
  padding: 5px 20px;
  margin: 0 20px;
}

.newsletterSubmitBtn select {
  background-color: #cf4633;
  color: #fff;
}

.quill {
  height: 400px;
  margin: 50px 0;
}

.custom-file-input {
  height: 200px; /* You can adjust the height as needed */
  border: 2px dashed #ccc;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.custom-file-input img {
  max-width: 100%;
  max-height: 100%;
}

.custom-file-input p {
  margin: 0;
}

.custom-file-input::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.newsletterSubject {
  margin-bottom: 10px;
}

.newsletterSubject input {
  background-color: transparent;
  border: 2px dashed #ccc;
  border-radius: 15px;
  font-size: 20px;

  height: 70px;
  outline: none;
  width: 100%;
}

.newsletterSubject input::placeholder {
  padding-left: 5px;
  font-size: 20px;
}
