.sidebarIcon {
  margin-right: 5px;
}

/* Dashboard General */
.dashboardGeneral {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  height: fit-content;
}

/* left container on the dashboard */
.dashboardConLeft {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.dashboardConLeft p {
  margin: 30px 0;
}

.dashboardProfile {
  border: 1px solid lightgray;
  margin-top: 60px;
}

.dashboardProfile h3 {
  margin: 25px 0;
}

.dashboardProfile ul {
  text-align: left;
}

.dashboardProfile li {
  margin: 25px 0;
  text-align: left;
}

.dashboardProfileImage {
  border-radius: 54px;
  width: 100px;
  height: 100px;
  background-color: #1b1b1b;
  margin: 0 auto;
  margin-top: -50px;
}

/* profile information */
.profileInfo {
  display: flex;
  text-align: left;
  justify-content: space-evenly;
  padding: 20px 0;
}

.profileInfoKeys {
  display: flex;
  flex-direction: column;
}

.profileInfoKeys > * {
  margin: 10px 0;
}

.profileInfoValues {
  display: flex;
  flex-direction: column;
}

.profileInfoValues > * {
  margin: 10px 0;
}

/* right container on the dashboard */
.dashboardConRight {
  height: 100%;
  padding: 10px;
}

.dashboardMetrics {
  display: flex;
  justify-content: space-evenly;
  background-color: #c4c4c9;
  margin: 20px 10px;
  padding: 10px 0;
  border-radius: 25px;
}

.dashboardMetrics p {
  margin: 0;
}

.dashboardStatistics {
  height: 100%;
}

.statsTextRow {
  display: flex;
  justify-content: space-between;
}
.statsTextRow select {
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #c4c4c9;
}

.statsTextRow select:focus-visible {
  outline: none;
}

.dashboardSchedule {
  margin: 30px 0;
  text-align: left;
}

.dashboardScheduleContainer {
  display: flex;
  box-shadow: 0 2px 4px 0 rgb(0, 0, 0, 0.24);

  border-radius: 15px;
  margin: 10px 0;
  padding: 10px;
  text-align: left;
  align-items: center;
}

.dashboardScheduleContainer p {
  margin: 0;
}

.dashboardScheduleText {
  margin-left: 20px;
}
