.header {
  height: 90vh;
  background-color: #cf4633;
  color: #fff;
  position: relative;
}

.headerText {
  text-align: left;
  padding-top: 100px;
}

.headerText h1 {
  font-size: 55px;
  line-height: 1.07;
}

.headerText h5 {
  margin: 20px 0;
}

.headerText span {
  display: block;
  color: #e1bd27;
}

.headerImage {
  display: grid;
  place-content: center;
  padding: 0 20px;
}

.headerImage img {
  max-width: 600px;
  height: auto;
}

.headerRow {
  position: relative;
  z-index: 4;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

.custom-svg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-svg svg {
  position: relative;
  display: block;
  width: calc(167% + 1.3px);
  height: 168px;
  transform: rotateY(180deg);
}

.custom-svg .shape-fill {
  fill: #ffffff;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

@media screen and (max-width: 991px) {
  .header {
    height: 100vh;
  }

  .header h1 {
    font-size: 40px;
  }

  .headerImage img {
    max-width: 400px;
  }

  .headerText {
    padding-top: 20px;
    text-align: center;
  }

  .headerImage img {
    max-width: 500px;
  }

  .headerLinks {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
}

.headerCtaGalaxy img,
.headerCtaHuawei img {
  max-width: 160px;
  height: auto;
}

@media (max-width: 767px) {
  .custom-svg svg {
    width: calc(129% + 1.3px);
    height: 145px;
  }
}

@media screen and (max-width: 507px) {
  .header {
    overflow: hidden;
  }
  .headerText h1 {
    font-size: 45px;
  }

  .headerImage img {
    max-width: 400px;
  }

  .headerCtaGalaxy img,
  .headerCtaHuawei img {
    max-width: 120px;
  }

  .custom-svg svg {
    width: calc(100% + 1px);
    height: 125px;
  }
}

@media screen and (max-width: 425px) {
  .headerImage {
    display: none;
  }
}

@media screen and (max-width: 325px) {
  .headerText h1 {
    font-size: 30px;
  }

  .headerImage img {
    max-width: 300px;
  }

  .headerCtaGalaxy img,
  .headerCtaHuawei img {
    max-width: 90px;
    height: auto;
  }
}
