.navBrand {
  font-family: "Raleway", sans-serif;
  font-family: 600;
}

.navBrand a {
  text-decoration: none;
  color: #fff;
}

.navLink {
  font-family: "Libre Baskerville", serif;
  text-decoration: none;
}
.navLink a {
  text-decoration: none;
  color: inherit;
}
.navi {
  background-color: #cf4633;
  padding: 10px 0;
  font-weight: bold;
}

.scrolled {
  position: fixed;
  top: 5px;
  left: 0;
  width: 100%;
  border-radius: 25px;
  background-color: #cf4633;
  z-index: 500;
  animation: slide-down 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
