.adminMain {
  height: 100vh;
  width: 80vw;
  padding: 0 20px;
  overflow-y: scroll;
}

.addAdmin {
  align-items: center;
  background-color: #cf4633;
  color: #fff;
  display: flex;
  border-radius: 25px;
  justify-content: center;
  text-decoration: none;
  height: 50px;
  width: 150px;
}

.adminSalutation {
  text-align: left;
}

.adminSalutation h2 {
  margin: 20px 0 20px 5px;
}

.adminTable table {
  width: 800px;
  text-align: center;
}

.adminTable {
  border: 1px solid darkgray;
  border-radius: 10px;
  width: fit-content;
  margin-top: 30px;
  padding: 20px;
}

.adminTable tr {
  text-align: left;
  margin: 15px;
}

.adminTable th {
  background-color: #f1f1f1;
}
