.cta {
  margin: 100px 10px;
}

.ctaHeading h2 {
  font-size: 50px;
  margin-bottom: 30px;
}

.cta img {
  max-width: 700px;
  height: auto;
}

.ctaCon {
  background-color: #cf4633;
  color: #fff;
  border-radius: 25px;
  padding: 10px 30px;
}

.ctaTextCol {
  padding-top: 100px;
  text-align: left;
}

.ctaTextCol h2 {
  font-weight: bold;
}

.ctaTextCol p {
  font-size: 20px;
}

.ctaImgCol {
  display: grid;
  place-content: center;
}

.ctaGalaxy img,
.ctaHuawei img {
  max-width: 160px;
  height: auto;
}

@media screen and (max-width: 507px) {
  .ctaGalaxy img,
  .ctaHuawei img {
    max-width: 120px;
  }
}

@media screen and (max-width: 425px) {
  .ctaHeading h2 {
    font-size: 40px;
  }

  .ctaTextCol p {
    font-size: 16px;
  }
}

@media screen and (max-width: 300px) {
  .ctaGalaxy img,
  .ctaHuawei img {
    max-width: 90px;
  }
}
