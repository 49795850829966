.sessionMain {
  height: 100vh;
  width: 80vw;
  padding: 0 20px;
  overflow-y: scroll;
}

.sessionContent {
  text-align: left;
  margin-top: 20px;
}

.sessionTable table {
  width: 500px;
  text-align: center;
}

.sessionTable {
  border: 1px solid darkgray;
  border-radius: 10px;
  width: fit-content;
  padding: 20px;
  margin-top: 30px;
}

th,
td {
  border-bottom: 1px solid lightgray;
  padding: 10px 0;
}
