.privacy {
  text-align: left;
}

.privacy h1,
.privacy h2,
.privacy p {
  margin: 50px 10px;
}

.privacy span {
  margin: 0 10px;
}
