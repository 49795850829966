.loader{
    display: flex;
    gap: 0.2rem;
    justify-content: center;
    list-style: none;
    margin-top: 15px;
}

.loader li{
    width: 40px;
    height: 40px;
    background: var(--color);
    border-radius: 50%;
    animation: grow 1.6s ease-in-out infinite;
    animation-delay: var(--delay);
    box-shadow: 0 0 50px var(--color);
}

@keyframes grow {
    0%,
    40%,
    100% {
        transform: scale(0.2);
    }
    20%{
        transform: scale(1);
    }
}