/* Dashboard greetings */
.dashboardGreetings {
  text-align: left;
  margin-top: 20px;
  padding: 10px;
  height: 100px;
  width: 400px;
}

.dashboardGreetings h2 {
  margin: 0;
}
