.dashboardCover {
  height: 100%;
  width: 100%;
  display: flex;
}

.dashboardSidebar {
  background-color: #cf4633;

  /* color: #fff; */
  display: flex;
  justify-content: center;
  text-align: left;
  width: 20vw;
  height: 100vh;
}

.dashboardSidebar ul {
  margin-top: 80px;
}

.dashboardSidebar li {
  color: #fff;
  border-radius: 15px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  padding: 10px;
}

.dashboardMain {
  height: 100vh;
  width: 80vw;
  padding: 0 20px;
  overflow-y: scroll;
}
