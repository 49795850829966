.blogForm {
  text-align: left;
}

.blogForm h2 {
  margin: 50px 0;
}

.blogTitle {
  margin-bottom: 10px;
}

.blogTitle input {
  background-color: transparent;
  border: 2px dashed #ccc;
  border-radius: 15px;
  font-size: 20px;

  height: 70px;
  outline: none;
  width: 100%;
}

.blogTitle input::placeholder {
  padding-left: 5px;
  font-size: 20px;
}

.blogTopic {
  display: flex;
  align-items: center;
}

.blogTopic h2 {
  margin-left: 10px;
}

.blogBtns {
  display: flex;
  justify-content: flex-end;
}

.blogScheduleBtn {
  background-color: #f9fbff;
  border: 1px solid #000;
  border-radius: 15px;
  padding: 5px 20px;
}

.blogSubmitBtn {
  background-color: #cf4633;
  border-radius: 15px;
  color: #fff;
  padding: 5px 20px;
  margin: 0 20px;
}

.quill {
  height: 400px;
  margin: 50px 0;
}

.dragArea {
  height: 200px; /* You can adjust the height as needed */
  border: 2px dashed #ccc;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.dragArea input {
  display: none;
}

.customFileInput {
  display: flex;
  align-items: center;
  justify-content: center;
}

.customFileInput p {
  margin: 0 5px;
}

.modalHeader {
  flex-direction: column;
  align-items: start;
}

.scheduleDate,
.scheduleTime {
  display: flex;
  flex-direction: column;
}

.scheduleDate label,
.scheduleTime label {
  font-weight: bold;
}

.scheduleDate input,
.scheduleTime input {
  text-transform: uppercase;
  padding: 15px 5px;
  border-radius: 10px;
  border: 1px solid gray;
  outline: none;
  margin: 10px 0;
}
