.searchBarContainer {
  display: flex;
  border-radius: 40px;
  border: 0.5px solid #c9c9c9;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgb(0, 0, 0, 0.24);
  gap: 10px;
  padding: 15px;
  width: 40%;
}

.searchInput {
  border: none;
  outline: none;
  color: #a3a3a4;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
}
