.detail {
  width: 73rem;
  margin: 5rem auto;
  padding: 2rem;
}

.detail h1 {
  line-height: 1.1;
  color: #222;
  font-size: 3.4rem;
}

.detail h2 {
  margin: 10px 0;
  font-size: 2rem;
}

.img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.info {
  margin: 1rem 0;
  font-size: 1rem;
}

.date {
  border-right: 1px solid #000;
  margin-right: 0.5rem;
  padding-right: 0.5rem;
}

.article {
  color: #222;
}

.article p {
  text-align: left;
  white-space: pre-wrap;
}

@media screen and (max-width: 717px) {
  .detail {
    width: 80%;
  }

  .detail h1 {
    font-size: 2.5rem;
  }

  .detail h2 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 375px) {
  .detail h1 {
    font-size: 2.1rem;
  }

  .detail h1 {
    font-size: 1.1rem;
  }

  .article {
    line-height: 1.5;
  }
}
