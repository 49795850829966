.whyus {
  padding: 30px 20px;
}

.whyusHeading {
  margin: 80px 0;
  font-weight: bold;
  position: relative;
}

.whyusHeading h2 {
  font-size: 50px;
}

.whyusList span {
  font-size: 20px;
}

.whyusColImg {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  padding: 0;
  border-radius: 25px;
}

.whyusColImg img {
  max-width: 100%;
  height: 530px;
  border-radius: 25px;
}

.whyusListCon {
  margin: 20px 0;
}

.whyusList {
  margin-left: 0;
  padding-right: 0;
  text-align: left;
  list-style-type: none;
}

.whyusList li {
  margin: 10px 0;
  counter-increment: step-counter;
}

.whyusList li::before {
  content: counter(step-counter);
  color: #fff;
  margin-right: 5px;
  font-size: 80%;
  background-color: #cf4633;
  font-weight: bold;
  padding: 3px 8px;
  border-radius: 3px;
}

.whyusList li span {
  display: block;
  font-weight: bold;
}

@media screen and (max-width: 425px) {
  .whyusColImg img {
    height: auto;
  }

  .whyusHeading h2 {
    font-size: 35px;
  }
}
