.accordionContainer {
  padding: 100px;
}

.accordionContainer h4 {
  margin: 30px;
}

.accordionContainer h4 a {
  text-decoration: none;
  color: #cf4633;
}

.accordion-item {
  text-align: left;
}

.accordion-button:focus {
  border-color: none;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  background-color: #e39b9b;
  color: #000;
}

@media screen and (max-width: 500px) {
  .accordionContainer {
    padding: 50px;
  }

  .accordionContainer h4 {
    margin: 20px;
    font-size: 18px;
  }
}
