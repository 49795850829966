.blogContainer {
  margin: 30px 0 0 0;
  text-align: left;
  border-bottom: 2px solid hsla(0, 0%, 0%, 0.198);
}

.blogImg {
  text-align: center;
}

.blogImg img {
  max-width: 100%;
  height: 300px;
}

.blogImg,
.blogContent {
  margin: 30px 0;
}

.blogContent p {
  margin: 20px 0;
}

.blogContent button {
  padding: 5px;
  background-color: transparent;
  border: 2px solid #c83e3ee7;
  text-transform: uppercase;
  font-weight: bold;
}

.blogContent button a {
  color: inherit;
  text-decoration: none;
}

.blogContent button:hover {
  background-color: #c83e3ee7;
  color: #fff;
}
