.aboutHeading {
  height: 100vh;
  display: grid;
  place-content: center;
  padding: 0 50px;
}

.aboutHeading h1 {
  line-height: 1.5;
  color: brown;
}
.aboutHeading p {
  margin: 20px 0;
}

.aboutDescription {
  background-color: #f9e2dd;
  text-align: left;
  padding: 80px 40px;
  margin: 10px 20px;
}

.aboutDescription h2 {
  color: brown;
}

.aboutDescription p {
  margin: 30px 0;
}

.aboutStatements {
  text-align: left;
  margin: 100px 0 20px;
  justify-content: space-evenly;
}
.aboutStatements h3 {
  color: brown;
}

.aboutVision,
.aboutCompany {
  background-color: #f9e2dd;
  padding: 20px;
  border-radius: 25px;
  margin: 20px 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
}

.aboutTeam {
  text-align: left;
  margin: 100px 0;
}

.aboutTeam h2 {
  margin: 50px 0;
  color: brown;
}

.aboutFounderImg,
.aboutFounderImg img {
  width: max-content;
  border-radius: 25px;
}

.aboutFounderImg {
  display: grid;
  place-content: center;
  padding: 20px 0;
}

.aboutFounder {
  padding: 20px 10px;
  margin: 0 5px;
}

.card {
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
}
@media screen and (max-width: 425px) {
  .aboutHeading {
    height: auto;
    padding: 20px;
    text-align: left;
  }

  .aboutHeading h1 {
    line-height: 1.2;
    font-size: 25px;
  }

  .aboutHeading p {
    margin: 0;
  }

  .aboutStatements {
    margin: 50px 0 20px;
  }
  .aboutVision,
  .aboutCompany {
    padding: 10px;
    border-radius: 0;
  }

  .aboutDescription {
    padding: 50px 15px;
  }

  .aboutFounderImg img {
    max-width: 300px;
  }

  .teamCard {
    display: grid;
    place-content: center;
    margin: 20px 0;
  }
}
