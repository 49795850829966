.footer {
  background-color: #28231d;
  color: #fff;
  padding: 5rem 0;
  display: flex;

  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  position: relative;
}

.footer h2 {
  font-weight: bold;
}

.footer h2:hover {
  color: #cf4633;
}

.footer ul,
.footerSocial,
.footerCopyright {
  list-style: none;
  display: flex;
  margin: 20px 15px;
}

.footer li {
  margin: 0 20px;
}

.footer li a {
  text-decoration: none;
  color: #fff;
}

.footer li a:hover {
  color: #cf4633;
}

.footerContent {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
}

.footerSocial a {
  margin: 0 10px;
}

.footerSocialIcon {
  color: #fff;
}

.footerSocialIcon:hover {
  color: #cf4633;
}

.newsletterText {
  margin: 10px;
}

.newsletterEmail,
.newsletterButton {
  margin: 10px;
}

.newsletterEmail {
  color: #fff;
  padding: 10px 40px;
  border-radius: 15px;
  background-color: transparent;
  border: 2px solid #cf4633;
  outline: none;
}

.newsletterEmail::placeholder {
  color: #fff;
}

.newsletterButton {
  font-family: "Libre Baskerville", serif;
  font-weight: bold;
  margin: 0 10px;
  padding: 10px 15px;
  border-radius: 15px;
  outline: none;
  background-color: #fff;
  border: 2px solid #fff;
  color: #cf4633;
}

.newsletterButton:hover {
  background-color: #cf4633;
  border: 2px solid #cf4633;
  color: #fff;
  cursor: grab;
}

.modalBtn,
.modalBtn:hover {
  background-color: #cf4633;
  border: 2px solid #cf4633;
}

@media screen and (max-width: 425px) {
  .footer li {
    margin: 0 10px;
  }

  .footer ul {
    flex-direction: column;
    list-style-type: none;
  }

  .footer ul li {
    margin: 4px 0;
  }
}

@media screen and (max-width: 320px) {
}
