@tailwind base;
@tailwind components;
@tailwind utilities;
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  margin: 0;
  box-sizing: border-box;
  height: 100%;
}

h1,
h2,
h3,
span {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
}

p,
li {
  font-family: "Libre Baskerville", serif;
}
