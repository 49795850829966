.features {
  padding: 50px 0;
  overflow: hidden;
}

.features li {
  list-style: none;
  background-image: url("../../../public/solochatLogo.png");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: -1px 15px;
  padding: 10px 20px;
}

.featuresHeading {
  margin: 30px auto;
}

.featuresHeading h2 {
  font-size: 50px;
}

.featuresHeading p {
  font-size: 20px;
}

.featuresContainer {
  text-align: left;
  padding: 20px;
}

.featuresColOneImage {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}

.bgcircletop,
.bgcirclebottom {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: #cf4633;
  border-radius: 50%;
  z-index: 1;
}
.bgcircletop {
  top: 0;
  left: 10%;
}

.bgcirclebottom {
  bottom: 20%;
  right: 10%;
}

.featuresColOneImage img {
  max-width: 700px;
  height: auto;
  z-index: 2;
}

@media screen and (max-width: 425px) {
  .featuresHeading h2 {
    font-size: 40px;
  }

  .featuresHeading p {
    font-size: 18px;
  }
}
