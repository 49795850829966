.card {
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  padding: 20px;
  margin: 2rem auto;
  width: 50%;
  max-width: 100%;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .card {
    width: 70%;
  }
}

@media screen and (max-width: 425px) {
  .card {
    width: 100%;
  }
}

.form {
  padding: 1rem;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.control input,
.control textarea,
.control select {
  display: block;
  font: inherit;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  padding: 0.25rem;
  width: 100%;
}

.action {
  margin-top: 1rem;
  text-align: right;
}

.action button {
  background-color: #cf4633;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border: 1px solid #cf4633;
  border-radius: 4px;
  font-weight: bold;
}

.action button:hover,
.action button:active {
  background-color: #4f1811;
  border-color: #a53728;
}
