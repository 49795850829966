.blogCover {
  padding: 30px;
  background-color: #f7f1f0;
  height: 100vh;
  width: 80vw;
  overflow-y: scroll;
  text-align: left;
}

/* NO BLOG POST*/
.blogContainerContent {
  background-color: #fff;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blogContainerContentNoBlog {
  margin: 0 auto;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.addNewPostBtn {
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #cf4633;
  color: #fff;
  display: flex;
  padding: 20px;
  border-radius: 25px;
  width: 190px;
}
